<template>
  <div>
    <div class="row justify-content-center justify-content-around">
      <div class="card col-12 col-md-12 p-1">
        <div>
          <div class="title head">
            <p class="h2" style="color: #558cef">ประมวลผลค่าเสื่อมประจำปี</p>
          </div>
          <hr />
          <!-- search input -->
          <div class="custom-search row justify-content-between">
            <div class="col-12 col-md-3 mb-1">
              <b-button v-b-modal.modal-1>ประมวลผลค่าเสื่อม</b-button>
            </div>
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export
                        <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>
                
                    <b-dropdown-item @click="exportExcel">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      <span>Excel</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <feather-icon icon="ClipboardIcon" class="mr-50" />
                      <span>PDF</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                  <b-avatar :src="props.row.avatar" class="mx-1" />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'insert'">
                  <span>
                    <b-button variant="outline-info" :to="{ name: 'inannualcheck', params: { id: props.row } }">
                      <feather-icon icon="EyeIcon" size="16" class="mx-1"
                    /></b-button>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage1(value))"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="handlePagechange1($event)"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>

    <!-- //model -->
    <div>
      <b-modal
        id="modal-1"
        cancel-variant="outline-secondary"
        ok-title="บันทึก"
        cancel-title="ยกเลิก"
        title="ประมวลผลค่าเสื่อมประจำปี"
        @ok="post_data"
      >
        <b-form>
          <div>
            <b-form-group label-cols="4" label-cols-lg="4" label="ปีงบประมาณ:">
              <template v-slot:label> ปีงบประมาณ <span class="text-danger"> *</span> </template>
              <b-form-input id="basicInput" v-model="from.budget_year" placeholder="ปีงบประมาณ" />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="4" label="วันที่:">
              <template v-slot:label> วันที่ <span class="text-danger"> *</span> </template>
              <b-form-datepicker
                placeholder="วันที่"
                id="example-datepicker"
                v-model="from.target_date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="th"
              />
            </b-form-group>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { BFormInput, BRow, BCol, BFormGroup } from 'bootstrap-vue'
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { BButton } from 'bootstrap-vue'
import { BFormFile } from 'bootstrap-vue'
import { BFormSelect } from 'bootstrap-vue'
import { BFormDatepicker } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import API from '@/views/connectDB/condb.js'
import { saveExcel } from '@progress/kendo-vue-excel-export'

export default {
  components: {
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
  },

  data() {
    return {
      show: false,
      from: {
        budget_year: '',
        target_date: '',
      },
      pageLength: 5,

      dir: false,
      columns: [
        {
          label: 'วันที่',
          field: 'target_date',
        },
        {
          label: 'ปีงบประมาณ',
          field: 'budget_year',
        },

        {
          label: 'ประมวลผล',
          field: 'insert',
        },
      ],
      rows: [],
      total: '',
      searchTerm: '',
    }
  },
  mounted() {
    this.get_data()
  },
  methods: {
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: 'ประมวลผลค่าเสื่อมประจำปี',
        columns: [
          {
            title: 'ประมวลผลค่าเสื่อมประจำปี',
            headerCellOptions: { textAlign: 'center' },
            children: [
              {
                title: 'วันที่',
                field: 'target_date',
              },
              {
                title: 'ปีงบประมาณ',
                field: 'budget_year',
              },
            ],
          },
        ],
      })
    },
    handlePagechange1(event) {
      this.currentPage = event
      this.get_data()
    },
    handleChangePage1(event) {
      this.pageLength = event
      this.get_data()
    },
    async get_data() {
      this.show = true
      const { access_token } = await this.access_token()
      const url = `${API}depreciation?budget_year=2540&target_date=2022-03-1&_page=${this.currentPage}&_limit=${this.pageLength}`
      const head = {
        headers: {
          Authorization: await access_token,
        },
      }
      const res = await axios.get(url, head)
      // console.log(res.data.message)
      this.rows = res.data.message.data
      this.total = res.data.message.total
      this.show = false
    },
    async post_data() {
      const { access_token } = await this.access_token()
      const url = `${API}depreciation`
      const data = {
        budget_year: this.from.budget_year,
        target_date: this.from.target_date,
      }
      const head = {
        headers: {
          Authorization: await access_token,
        },
      }
      const res = await axios.post(url, data, head)
      // console.log(res.data.status)
      if (res.data.status === 200) {
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: 'บันทึก',
          showConfirmButton: false,
          timer: 1500,
        })
      } else {
        Swal.fire({
          position: 'top-center',
          icon: 'error',
          title: 'บันทึกสำเร็จ',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, '', {
          headers: {
            Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
          },
        })
      ).data.message
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
